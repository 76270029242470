import React, { useState } from 'react'
import { SubformProps } from "."
import { DecisionProResponse, dollars, dollarsToNum, estimatedClosingCosts } from '@oneethos/shared'
import ReviewApplication from './review-application'
import { useAppState } from '../../hooks'

export const SubmitApplication = (props: SubformProps) => {
  const { update, formData, onSubmit, onTestSubmit } = props
  let rate = `${(formData.useAutoDebit === 'no' ? formData.interest + 0.25 : formData.interest)}%`
  if (formData.incentive) {
    rate = `${formData.incentive.incentivizedRate}%**`
  }

  const closingCosts = estimatedClosingCosts(
    dollarsToNum(formData.loanAmount),
    formData.installationAddress?.state,
    formData.projectType
  )
  const amountFinanced = dollarsToNum(formData.loanAmount) + closingCosts
  const { tenant } = useAppState()

  return <div className="submit-application">
    <p>
      Almost done! Before submitting your application, please review all details for accuracy.
    </p>

    <ReviewApplication {...props} />

    <h2 className="mt-5">Submit Application Request</h2>

    <div className="sa-summary my-3">
      <div><b>System Price:</b>&nbsp;{dollars(formData.systemCost)}</div>
      <div><b>Estimated Loan Fees*:</b>&nbsp;{dollars(closingCosts)}</div>
      <div><b>Total Amount Financed:</b>&nbsp;{dollars(amountFinanced)}</div>
      <div><b>Loan Term:</b>&nbsp;{formData.loanDurationMonths} months | {rate}</div>
    </div>

    <div className="text-small mb-3">
      *This is the estimate of the loan fees associated with processing your loan. This amount is
      rolled into the loan to be financed over the 30-year term.
    </div>

    {formData.incentive ? <div className="text-small mb-3">
      **With qualifying incentive. Incentive terms can be found <a target="_blank" href={`https://${formData.incentive.termsUrl}`}>here</a>.
    </div> : null}

    <p>
      By submitting your application request you certify that complete and accurate responses have 
      been provided for all questions on the application, and agree to allow {tenant.config.name} to
      verify your personal information and check your credit.
    </p>

    <div className="buttons text-start">
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => onSubmit()}
      >Submit Application</button>
      {process.env.REACT_APP_NODE_ENV === 'test' ? <>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => onTestSubmit('A')}
        >Test Approved</button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => onTestSubmit('P')}
        >Test Pending</button>
      </> : null}
    </div>
  </div>
}

export default SubmitApplication