import React from 'react'
import { FormInput, SubformProps } from "."
import { getBrowserFingerprintData } from "../../browser-fingerprint"
import { Spinner } from '@fluentui/react'
import { AddressInputs } from '../../components/forms'
import { StreetAddress, Validate, isServiceableAddress } from '@oneethos/shared'
import { useAppState } from '../../hooks'

const { isValidDollars } = Validate

export const InitialLoanRequest = ({ formData, org, update, save, saving }: SubformProps) => {
  const { state, country } = (formData.installationAddress || {})
  const { tenant: { config } } = useAppState()

  const {
    termsAndConditionsUrl = "https://uploads-ssl.webflow.com/635ae89b651fdf01ef0360a7/63fd1bb51695e86a2e73a542_FINAL-Terms-and-Conditions-Climate-First_Accessible.pdf",
    privacyPolicyUrl = "https://uploads-ssl.webflow.com/635ae89b651fdf01ef0360a7/63fd1b125c945e62586bc929_FINAL-Privacy-Policy-Accessible.pdf",
    esignAgreementUrl = "https://uploads-ssl.webflow.com/635ae89b651fdf01ef0360a7/63fd34e2f8e1cd8416c96c95_6251ac8b953d1e2a1dfee967_eSign.pdf"
  } = config

  return <div>
    <h2>Solar Loan Application</h2>
    <AddressInputs
      address={formData.installationAddress || {}}
      label="Address where solar panels will be installed"
      onChange={address => {
        update({
          ...formData,
          installationAddress: address,
          userAddress: address
        })
      }}
    />
    {state ? <>
      {!StreetAddress.isValid(formData.installationAddress) ? <div>
        <div className="alert alert-info">
          Please confirm you have entered a complete address.
        </div>
      </div> : <>
        {!isServiceableAddress(state, country, org) ? <div className="alert alert-warning">
          We're sorry, we do not yet support projects at your address.
        </div> : <>
          <FormInput
            field='loanAmount'
            value={formData.loanAmount}
            onChange={s => update('loanAmount', s)}
          />
          <FormInput
            field='avgMonthlyUtilityBill'
            value={formData.avgMonthlyUtilityBill}
            placeholder="$75.00"
            onChange={s => update('avgMonthlyUtilityBill', s)}
          />
          <p> Please review our&nbsp;
            <a href={esignAgreementUrl} target="_blank">eSign Agreement</a>,
            &nbsp;
            <a href={privacyPolicyUrl} target="_blank">privacy</a>,
            &nbsp;
            <a href={termsAndConditionsUrl} target="_blank">terms and conditions.</a>
            &nbsp;
            <br />
            <br />
            <b>IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT</b>
            <br />
            To help the government fight the funding of terrorism and money laundering
            activities, Federal law requires all financial institutions to obtain, verify
            and record information that on personal accounts identifies each person who
            opens an account. In addition, on legal entity accounts, we will require
            identification on beneficial owners and controlling person. What this means
            for you: When you open an account, we will ask for your name, address, date of
            birth, and other information that will allow us to identify you. We may also
            ask to see your driver’s license or other identifying documents.
          </p>
          <div className="form-group" style={{ cursor: 'pointer' }}>
            <label>
              <input type="checkbox"
                checked={formData.agreesToTermsAndConditions?.agrees || false}
                onChange={ev => update('agreesToTermsAndConditions', ev.target.checked ? {
                  browser: getBrowserFingerprintData(),
                  timestamp: new Date(),
                  agrees: true
                } : false)}
              />
              &nbsp;
              <b>I have read and agree with eSign Agreement, privacy, terms and conditions.</b>
            </label>
          </div>
          {(
            formData.loanAmount &&
            formData.agreesToTermsAndConditions &&
            isValidDollars(formData.avgMonthlyUtilityBill) === ''
          ) ? (
            <div className="buttons">
              <button type="button"
                className="btn btn-primary"
                disabled={saving}
                onClick={() => save()}
              >{saving ? <Spinner /> : 'Continue'}</button>
            </div>
          ) : <div className="alert alert-secondary">
            Please confirm address, agree to terms, and average monthly utility bill to proceed to the next step.
          </div>}
        </>}
      </>}
    </> : null}
  </div>
}

export default InitialLoanRequest