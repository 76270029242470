import { Dictionary, Incentive, ITenant } from "@oneethos/shared"
import { Reducer } from "react"

export interface TenantState {
  loading: boolean
  error?: string
  config?: Partial<ITenant>
  incentive?: Incentive
}

export type TenantActionTypes =
  'LOAD_TENANT' |
  'SET_COBRANDING' |
  'SET_TENANT' |
  'SET_TENANT_ERROR'

export type TenantAction = {
  type: TenantActionTypes
  data?: any
}

export const tenantReducer: Reducer<TenantState, TenantAction> = (
  state: TenantState,
  action: TenantAction
): TenantState => {
  switch (action.type) {
    case 'LOAD_TENANT':
      state = {
        ...state,
        loading: true,
      }
      break
    case 'SET_TENANT':
      state = {
        ...state,
        loading: false,
        config: action.data
      }
      break
    case 'SET_TENANT_ERROR':
      state = {
        ...state,
        error: action.data,
        loading: false
      }
      break
    case 'SET_COBRANDING':
      state = {
        ...state,
        incentive: action.data
      }
      break
  }

  return state;
}

