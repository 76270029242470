import React, { useContext, useEffect, useRef, useState } from 'react'
import { useAppState, useLocalStorage } from '../hooks'
import './top-nav.scss'
import './menu.scss'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { FaSignInAlt, FaUserCircle } from 'react-icons/fa'
import { StorageKey } from '../lib'
import api from '../api-client'
import actions from '../actions'
import { AppContext } from '../appContext'
import InstallerService from '../services/installerService'

const UserMenu = ({ installer }) => {
  const { dispatch } = useContext(AppContext)
  const [impersonate, , clearImpersonate] = useLocalStorage(StorageKey.ImpersonateUserId, undefined)
  const [showMenu, setShowMenu] = useState(false)
  const navigate = useNavigate()

  const menu = useRef()

  useEffect(() => {
    const listener = ev => {
      if (!menu?.current?.contains(ev.target)) {
        setShowMenu(false)
      }
    }

    window.document.addEventListener('click', listener)

    return () => {
      window.document.removeEventListener('click', listener)
    }
  }, [])

  return <div className="user-menu dropdown">
    <div className="user-menu-btn"
      style={{ padding: '1em' }}
      onClick={() => setShowMenu(!showMenu)} ref={menu}>
      <FaUserCircle size={30} className="profile-icon" />
    </div>
    {showMenu && <div className="user-menu-dropdown dropdown-menu">
      <div className="dd-menu-item"><span>{installer.email}</span></div>
      <div className="dd-menu-item dd-link"><Link to="/profile">Profile</Link></div>
      <div className="dd-menu-item dd-link"
        onClick={() => {
          if (impersonate) {
            clearImpersonate()
          } else {
            api.post('/installers/logout').catch(ex => {
              console.error('error logging out', ex)
            })
            InstallerService.clearToken()
            dispatch(actions.logout())
            navigate('/login')
          }
        }}
      ><span>Logout</span></div>
    </div>}
  </div>
}

export const TopNav = () => {
  const { registration: { installer }, tenant } = useAppState()
  const navigate = useNavigate()
  const loc = useLocation()
  const [searchParams] = useSearchParams()
  const showCobranding = !!tenant?.incentive

  return <div className="top-nav">
    <div className="flex-space-between">
      <div className="d-flex">
      <div className="hdr-logo-wrap">
        {!tenant.loading ?
          <a href="#">
            {/* TODO: update cache-busting */}
            <img src={tenant?.config?.site === 'installer' ? "/images/oe-logo-wide.png" : "/api/tenant/logo"}
              loading="lazy"
              id="logo"
              sizes="(max-width: 479px) 83vw, (max-width: 767px) 72vw, 397.8984375px"
              alt="OE Logo"
              style={{ maxHeight: '70px', padding: '0.6em' }}
            />
          </a> : null
        }
        </div>
        
        {showCobranding ? <div className="d-flex flex-column ms-5 align-items-start">
          <label className="text-secondary fs-6">In Partnership With</label>
          <img src={tenant?.incentive?.financingPartnerLogo} loading="lazy" id="logo"
            alt="local financing partner logo"
            style={{ maxHeight: '40px' }}
          />
        </div> : null}
      </div>
      <div>
        {installer ? <UserMenu
          installer={installer}
        /> : null}
        {(!installer && loc.pathname !== '/login') ? (
          <div className="installer-profile-btn" onClick={() => navigate(`/login?${searchParams}`)}>
            <FaSignInAlt size={40} className="profile-icon" />
            <span>Login</span>
          </div>
        ) : null}
      </div>
    </div>
  </div>
}

export default TopNav