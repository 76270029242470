import { useEffect, useState } from "react"
import api from "../api-client"
import { Section } from "../components"
import { Spinner, SpinnerSize } from "@fluentui/react"
import { dollars, LoanApplication } from "@oneethos/shared"
import { useNavigate } from "react-router-dom"

export const ApplicationsPage = () => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    api.get('/c/loanapps/list').then((res: LoanApplication[]) => {
      setList(res)
    }).catch(ex => setError(ex.error || JSON.stringify(ex)))
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return <Section>
      <Spinner size={SpinnerSize.large} />
    </Section>
  }

  if (error) {
    return <div className="alert alert-danger">
      {error}
    </div>
  }

  return <div className="consumer-applications-page">
    <Section>
      <h4>My Loan Applications</h4>
      <ul className="list-group" style={{ cursor: 'pointer' }}>
        {list.map(item => <li key={item._id} className="list-group-item lapp" onClick={() => {
          navigate(`/apply/${item._id}`)
        }}>
          <div>
            <div><b>Amount:</b> {dollars(item.loanAmount)}</div>
            <div><b>Address:</b> {item.userAddress.streetAddress}</div>
            <div><b>Step:</b> {item.preSubmitStatus}</div>
          </div>
          {item.preSubmitStatus !== 'Submitted' ? <div>
            <a className="btn btn-primary btn-sm" href={`/apply/${item._id}`}>Resume Application</a>
          </div> : null}
        </li>)}
      </ul>
    </Section>
  </div>
}

export default ApplicationsPage