
export type Dictionary<T> = Record<string, T>

const UPLOAD_DOC_TYPES = {
  full_roof: 'Full Roof Funding Docs',
  solar_contract: 'Contract Docs',
  initial_funding_request: 'Initial Funding Docs',
  final_funding_request: 'Final Funding Docs',
  full_funding_request: 'Full Funding Docs',
  full_funding_docs_w_roof: 'Full Funding with Roof Docs',
  other: 'Other Docs',
  change_order: 'Change Order Docs',
}

export type UploadDocType = keyof typeof UPLOAD_DOC_TYPES

export const docTypeDescription = (type: UploadDocType): string => {
  return UPLOAD_DOC_TYPES[type] || '[unknown doc type]'
}

export type RequiredDocConfig = {
  _id?: string // only used during UI configuration
  slot: string
  instructions: string
}

export type InstallerStatus = {
  _id?: string
  status: string
  description?: string
  owner?: string
  sequence?: number
  uploadType?: UploadDocType
  deprecated?: boolean
  requiredDocuments?: RequiredDocConfig[]
  aliases?: string[] // other statuses that should be treated as equivalent
  progress?: number
  bg?: string
}

export type Incentive = {
  id: string
  termsUrl: string
  financingPartner: string
  financingPartnerLogo: string
  incentivizedRate: string
  estPriceWithIncentives: string
  incentiveDescription: string
}

export const getUploadSlots = (iscList: InstallerStatus[]): Dictionary<Dictionary<string>> => {
  const uploadSlots: Dictionary<Dictionary<string>> = {}
  iscList.forEach(statusConfig => {
    if (!statusConfig.uploadType) return // skip steps without uploadType
    uploadSlots[statusConfig.uploadType] = uploadSlots[statusConfig.uploadType] || {}
    statusConfig.requiredDocuments.forEach(reqDoc => {
      uploadSlots[statusConfig.uploadType][reqDoc.slot] = reqDoc.instructions
    })
  })

  return uploadSlots
}

export type IncomeDocument = {
  docType: string
  documents: File[]
}